/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

const $ = require('jquery');
const imagesLoaded = require('imagesloaded');
imagesLoaded.makeJQueryPlugin( $ );

window.WOW = require('wowjs').WOW;

import './bootstrap.js';

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';

$(function() {

})